import { RootState } from '@/store/store'
import { HYDRATE } from '@/store/actions'
import { createSlice } from '@reduxjs/toolkit'
import { RimSearchCriteraModel } from '@/types/api'

// Type for our state
export interface RimsState {
  rimsCriteria?: RimSearchCriteraModel
}

// Initial state
const initialState: RimsState = {}

// Actual Slice
export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setRimsCriteria: (state, action) => {
      state.rimsCriteria = action.payload
    },
    resetRimDestination: (state) => {
      if (state.rimsCriteria)
        state.rimsCriteria.description = ''
    }
  },
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, action) => {
      return {
        ...state,
        ...action.payload.search,
      }
    })
  },
})

export const {
  setRimsCriteria,
  resetRimDestination
} = searchSlice.actions

export const selectRimsCriteria = (state: RootState) => state.search.rimsCriteria

export default searchSlice.reducer